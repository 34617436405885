import React from "react";

import AssetDetail from "./AssetDetail";
import AssetsList from "./AssetsList";
import L, {Map} from "leaflet";
import {Asset} from "../../hooks/assets/dto/Asset";

interface AssetPanelProps {
    mapref: React.RefObject<Map>;
    loading: boolean;
    assets: Asset[];
    selectedAsset: string | undefined;
    setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function AssetPanel(props: AssetPanelProps) {
    const filterActiveAssets = (assets: Asset[]) => {
        return assets.filter(asset => asset.status !== "INACTIVE");
    };

    function onBackPressed() {
        if (props.mapref.current !== null) {
            const assetBounds = filterActiveAssets(props.assets)
                .filter(asset => asset.latestEvent !== undefined)
                .map(asset => asset.latestEvent!.location.rawCoordinates)
                .map(coordinate => [coordinate.latitude, coordinate.longitude]);

            let bounds = new L.LatLngBounds(assetBounds as L.LatLngBoundsLiteral);
            props.mapref.current.fitBounds(bounds);
        }

        props.setSelectedAsset(undefined)
    }

    function VehicleDetail() {
        let asset = props.assets.find((asset) => asset.id.toString() === props.selectedAsset);

        if (asset !== undefined) {
            return (<AssetDetail asset={asset} onBackPressed={onBackPressed}/>)
        } else {
            return (<></>);
        }
    }

    return props.selectedAsset === undefined ? (<AssetsList mapref={props.mapref} loading={props.loading} assets={props.assets}
                                                            setSelectedAsset={props.setSelectedAsset}/>) : VehicleDetail()
}

export default AssetPanel;
