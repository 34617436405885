import React, {createContext, useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { UserPreferences } from '../hooks/user/dto/UserPreferences';
import { MapLayer } from '../components/map/MapTileLayer';
import useUserApi from '../hooks/user/User';
import { DEFAULT_LABEL_LAYER, LabelLayerType } from '../components/map/LabelLayer';
import {UserAccountContext} from "./UserAccountProvider";
interface UserPreferencesContextProps {
    preferencesLoaded: boolean;
    setPreferencesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    colorPalette: string;
    setColorPalette: React.Dispatch<React.SetStateAction<string>>;
    largeText: boolean;
    setLargeText: React.Dispatch<React.SetStateAction<boolean>>;
    openDrawer: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    languageCode: string;
    setLanguageCode: React.Dispatch<React.SetStateAction<string>>;
    clusterEnabled: boolean;
    setClusterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    labelsEnabled: boolean;
    setLabelsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    mapType: MapLayer;
    setMapType: React.Dispatch<React.SetStateAction<MapLayer>>;
    labelType: LabelLayerType;
    setLabelType: React.Dispatch<React.SetStateAction<LabelLayerType>>;
    twentyFourHourEnabled: boolean;
    setTwentyFourHourEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserPreferencesContext = createContext<UserPreferencesContextProps>({
    preferencesLoaded: false,
    setPreferencesLoaded: () => { },
    colorPalette: "default",
    setColorPalette: () => { },
    largeText: false,
    setLargeText: () => { },
    openDrawer: true,
    setOpenDrawer: () => { },
    languageCode: "en",
    setLanguageCode: () => { },
    clusterEnabled: true,
    setClusterEnabled: () => { },
    labelsEnabled: true,
    setLabelsEnabled: () => { },
    mapType: MapLayer.GOOGLE_ROAD_MAP,
    setMapType: () => { },
    labelType: DEFAULT_LABEL_LAYER,
    setLabelType: () => { },
    twentyFourHourEnabled: true,
    setTwentyFourHourEnabled: () => { }
});

interface Props {
    children: React.ReactNode;
}

export const UserPreferencesProvider = ({ children }: Props) => {
    const { i18n } = useTranslation();
    const { setUserPreferences } = useUserApi()
    const user = useContext(UserAccountContext);

    const [preferencesLoaded, setPreferencesLoaded] = useState<boolean>(false);
    const [colorPalette, setColorPalette] = useState<string>("default");
    const [largeText, setLargeText] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [languageCode, setLanguageCode] = useState<string>(user?.user?.languageCode || "en");
    const [clusterEnabled, setClusterEnabled] = useState<boolean>(true);
    const [labelsEnabled, setLabelsEnabled] = useState<boolean>(true);
    const [mapType, setMapType] = useState<MapLayer>(MapLayer.GOOGLE_ROAD_MAP);
    const [labelType, setLabelType] = useState<LabelLayerType>(LabelLayerType.ALIAS);
    const [twentyFourHourEnabled, setTwentyFourHourEnabled] = useState<boolean>(true);

    useEffect(() => {
        updatePreferencesAfterStateChange();
        i18n.changeLanguage(languageCode);
    }, [openDrawer, languageCode, clusterEnabled, labelsEnabled, mapType, labelType, twentyFourHourEnabled]);

    useEffect(() => {
        i18n.changeLanguage(languageCode);
    }, [languageCode]);

    function updatePreferencesAfterStateChange() {
        if (preferencesLoaded) {
            const preferences: UserPreferences = {
                colorPalette: colorPalette,
                largeText: largeText,
                openDrawer: openDrawer,
                languageCode: languageCode,
                clusterEnabled: clusterEnabled,
                labelsEnabled: labelsEnabled,
                mapType: mapType,
                labelType: labelType,
                twentyFourHourEnabled: twentyFourHourEnabled
            };

            setUserPreferences(preferences);
        }
    }

    return (
        <UserPreferencesContext.Provider value={{
            preferencesLoaded, setPreferencesLoaded,
            colorPalette, setColorPalette,
            largeText, setLargeText,
            openDrawer, setOpenDrawer,
            languageCode, setLanguageCode,
            clusterEnabled, setClusterEnabled,
            labelsEnabled, setLabelsEnabled,
            mapType, setMapType,
            labelType, setLabelType,
            twentyFourHourEnabled, setTwentyFourHourEnabled,
        }}>
            {children}
        </UserPreferencesContext.Provider>
    );
};